@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

@font-face {
  font-family: 'cartridge-regular';
  src: url('./fonts/Cartridge-Regular.woff2') format('woff2');
}

:root {
  --headline-font: 'cartridge-regular', sans-serif;
  --body-font: 'Source Sans Pro', sans-serif;
  --text-color: #333;
  --white: #fff;
  --dark-purple: #3D1459;
  --pink: #EE68AF;
}

body {
  font-family: var(--body-font);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--headline-font);
  font-feature-settings: "ss01";
}

a {
  color: var(--pink);
  &:hover {
    text-decoration: none;
  }
}

.container {
  align-items: center;
  background: url("./images/2089141.jpg") no-repeat center center;
  background-size: cover;
  background-color: #282c34;
  color: white;
  display: flex;
  font-size: 1.1rem;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 0 20px;
  text-align: center;
  @media screen and (min-width: 576px) {
    font-size: 1.2rem;

  }
}

.site-logo {
  display: block;
  margin: 3rem auto 0;
  max-width: 100%;
  @media screen and (min-width: 576px) {
    max-width: 600px;
    width: 60vw;
  }
  @media screen and (min-width: 768px) {
    width: 50vw;
  }
}

.subhead {
  font-size: 1.8rem;
  font-weight: 400;
  margin: 0 0 1rem;
  @media screen and (min-width: 576px) {
    font-size: 2rem;
  }
  @media screen and (min-width: 768px) {
    font-size: 2.5rem;
  }
}

.sound-head {
  font-size: 1.6rem;
  font-weight: 400;
  margin: 1rem 0;
  @media screen and (min-width: 576px) {
    font-size: 1.8rem;
  }
  @media screen and (min-width: 768px) {
    font-size: 2rem;
  }
}

.main-control {
  margin: 2rem 0;
}

.btn-glossy {
  background-image: linear-gradient(rgba(255,255,255,0.3) 50%, rgba(255,255,255,0.0) 51%), linear-gradient(#9b59b6, #8e44ad);
  border-width: 0;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-feature-settings: "ss03";
  font-family: var(--headline-font);
  font-size: 1.5rem;
  padding: 15px;
  text-shadow: 0px 1px 0px rgba(0, 0, 0,0.3);
  text-transform: uppercase;
  text-shadow: 1px 2px 0px #333;
  transition: box-shadow 200ms;
  &:hover {
    box-shadow: 0 0 18px rgba(255,255,255, 0.3);
  }
}


.btn-glossy--secondary {
  background-image: linear-gradient(rgba(255,255,255,0.3) 50%, rgba(255,255,255,0.0) 51%), linear-gradient(#D94F30, #C3472B);
  font-size: 1.2rem;
  padding: 5px 15px;
}

.btn-glossy--tertiary {
  background-image: linear-gradient(rgba(255,255,255,0.3) 50%, rgba(255,255,255,0.0) 51%), linear-gradient(#D92588, darken(#D92588, 20%));
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: capitalize;
  padding: 5px 15px;
  @media screen and (min-width: 576px) {
    margin-top: 1.5rem;
  }
}

.audio-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(10px, 100%));
  justify-content: center;
  width: 100%;
  @media screen and (min-width: 576px) {
    grid-template-columns: repeat(2, minmax(10px, 50%));
  }
  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(2, minmax(10px, 30%));
  }
}

.audio-player {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 3rem;
}

.audio-player__icon {
  opacity: 80%;
  max-height: 200px;
  max-width: 200px;
}

.dd-wrapper {
  margin: 0 0 3rem;
  position: relative;
}

.dd-list {
  background: rgb(255,255,255,.8);
  border-radius: 10px;
  position: absolute;
  left: 50%;
  list-style-type: none;
  margin: 10px 0 0 0;
  padding: 10px;
  transform: translateX(-50%);
  z-index: 1;
  button {
    background-color: transparent;
    border: 0;
  }
}

.dd-list__item {
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  padding: 3px 5px;
  transition: all 500ms;
  &:hover {
    background-color: var(--dark-purple);
    button {
      color: var(--white);
      transition: all 500ms;
    }
  }
  button {
    color: var(--dark-purple);
    font-family: var(--headline-font);
    font-size: 1rem;
  }
}

// Range Slider

.hide {
  display: none;
}

input[type=range] {
  -webkit-appearance: none;
  background: none;
  margin: 18px 0;
  width: 50%;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #D92588;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #D92588;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #D92588;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}

// Footer
footer {
  background-color: rgba(#1b0e24, .7);
  font-size: .9rem;
  line-height: 1.4;
  margin: 20px -20px 0;
  padding: 10px 20px;
  @media screen and (min-width: 576px) {
    border-radius: 10px;
    font-size: 1rem;
    margin: 20px 0;
    width: 80%;
  }
}